
import {computed, defineComponent, onMounted, ref, UnwrapRef} from "vue";
import VueApexCharts from "vue3-apexcharts";
import ApiService from "@/core/services/ApiService";
import AccountBase from "@/views/crafted/account/Account-Base.vue";
import { useStore } from "vuex";
import { useI18n } from 'vue-i18n';
import {round} from "@popperjs/core/lib/utils/math";
import type {CertificateDataType} from "@/types/CertificateType";


export default defineComponent({
  name: "Account-Certificate",
  components: {
    AccountBase,
    VueApexCharts
  },
  setup() {
    const store = useStore();
    const user = computed(() => store.getters.currentUser);
    const { t } = useI18n();
    const currentLanguage = computed(() => store.getters.getCurrentLanguage || localStorage.getItem('currentLanguage'));
    const certificatesResponse = ref({
      status: 0,
      data: []
    });
    const certificates = ref<CertificateDataType[]>([]);
    const checkCopy = ref({});
    const loading = ref(true);

    onMounted(async () => {
      loading.value = false
      try{
          certificatesResponse.value = await ApiService.get("account/profile/certificates")
          if (certificatesResponse.value.status ===  200) {
            certificates.value = certificatesResponse.value.data
          }
      }catch (e) {
        console.log(e)
      }finally {
        loading.value = true
      }
    })

    const certificateName = (slug: string) => {
      return slug.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    }

    const scorePercentage = (score: number) => {
      if (!score) return 0;
      return  Math.floor(((score % 1000) * 100) / 100);
    }

    const checkCopyIndex = (index: number) => {
      checkCopy.value[index] = true;

      setTimeout(() => {
        checkCopy.value[index] = false;
      }, 1500)
    }
    const copyToClipboard = async (validation_id: string, index: number) => {
      if (!validation_id) return;

      checkCopyIndex(index)
      const baseURL = window.location.origin;
      const certificatePath = `/certificate/validate?id=${validation_id}`;
      const fullURL = `${baseURL}${certificatePath}`;

      try {
        await navigator.clipboard.writeText(fullURL);
      } catch (error) {
        console.error('Failed to copy URL:', error);
      }

    };

    const checkInProgress = (status: string) => {
      if (status == 'in_progress') {
        return {
          text: t(`${currentLanguage.value}.user_progress.in_progress`),
          color: 'badge-light-primary'
        }
      }else {
        return {
          text: t(`${currentLanguage.value}.user_progress.completed`),
          color: 'badge-light-success'
        }
      }
    }

    const sharedLinkedin = (validation_id: string) =>{

      const baseURL = window.location.origin;
      const certificatePath = `/certificate/${validation_id}`;

      const url = `${baseURL}${certificatePath}`;
      const urlBaseLinkedIn = 'https://www.linkedin.com/sharing/share-offsite/?url=';
      const urlToShare = encodeURIComponent(url);
      const urlFinal = `${urlBaseLinkedIn}${urlToShare}`;

      window.open(urlFinal, '_blank');

    }

    const certificateStatus = (status: CertificateDataType['certificate_status'] | undefined) => {
      if (status == 'completed') return true;
    }
    return {
      user,
      currentLanguage,
      t,
      certificates,
      certificateName,
      scorePercentage,
      copyToClipboard,
      checkCopy,
      checkInProgress,
      sharedLinkedin,
      loading,
      certificateStatus
    };
  },
});
