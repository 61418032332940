
import { computed, defineComponent } from "vue";

import { useStore } from "vuex";

export default defineComponent({
  name: "account-base",
  components: {
  },
  setup() {
    const store = useStore();
    const user = computed(() => store.getters.currentUser);
    const currentLanguage = computed(() => store.getters.getCurrentLanguage || localStorage.getItem('currentLanguage'));

    return {
      user,
      currentLanguage
    };
  },
});
